import React, { useState, useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useAnswer } from "../../../contexts/AnswerContext";

import Checkbox from "../../Inputs/Checkbox/Checkbox";
import Radio from "../../Inputs/Radio/Radio";
import Select from "../../Inputs/Select/Select";
import Text from "../../Inputs/Text/Text";
import TimeInput from "../../Inputs/TimePicker/TimePicker";
import SubForm from "../../Inputs/SubForm/SubForm";
import loadingGif from '../../../assets/images/loading.gif'
import "./PollForm.css"

function PollForm() {
    const state = useLocation().state;
    var root = document.documentElement;
    const { updateCheckedState, checkedState, handleSubmit } = useAnswer();
    const [respondentData, setRespondentData] = useState();
    const [options, setOptions] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [currentOptions, setCurrentOptions] = useState([]);
    const [subformQuestions, setSubformQuestions] = useState([]);
    const initial = useRef(0);
    const position = useRef(1);
    const [dummy, setDummy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [percentValue, setPercentValue] = useState(0);
    const [finish, setFinish] = useState(999);
    const [lastPositons, setLastPositions] = useState([]);
    const [skip, setSkip] = useState(false);
    const [subform, setSubform] = useState(false);
    const [error, setError] = useState("");
    const [likertOptions, setLikertOptions] = useState();
    const [likertScale,] = useState([
        {
            style: "far ",
            icon: "fa-angry",
        },
        {
            style: "far ",
            icon: "fa-frown",
        },
        {
            style: "far ",
            icon: "fa-meh",
        },
        {
            style: "far ",
            icon: "fa-smile",
        },
        {
            style: "far ",
            icon: "fa-laugh",
        },
        {
            style: "far ",
            icon: "fa-meh-blank",
        },
    ]);

    useEffect(() => {
        setRespondentData(state.respondentData);
        setLikertOptions([
            {
                "id": 0,
                "position": 1,
                "description": "Discordo Totalmente",
            },
            {
                "id": 1,
                "position": 2,
                "description": "Discordo",
            },
            {
                "id": 2,
                "position": 3,
                "description": "Não concordo nem discordo",
            },
            {
                "id": 3,
                "position": 4,
                "description": "Concordo",
            },
            {
                "id": 4,
                "position": 5,
                "description": "Concordo Totalmente",
            },
            {
                "id": 5,
                "position": 6,
                "description": "Sem condições de opinar",
            }
        ]);
        updateCheckedState(state.data.newState);
        setQuestions(state.data.questions);
        setOptions(state.data.options);

        //filtrando questões com action OUT, que estão fora do fluxo principal da pesquisa; 
        const outFilter = state.data.questions.filter((q) => q.action !== 'OUT');
        setFinish(outFilter[outFilter.length - 1].position + 1);

    }, [state, updateCheckedState, setQuestions, setOptions])

    const nextQuestion = () => {
        let validation = false;

        if (currentQuestion.metric === 1 || currentQuestion.metric === 2) {
            let checkboxes = document.querySelectorAll(`[name=answer${currentQuestion.id}]:checked`);
            if (checkboxes.length !== 0) {
                let otherInput = document.querySelectorAll('.other-text-input');
                if (otherInput[0] && otherInput[0].value === '' && !otherInput[0].disabled) {
                    setError("Responda a pergunta atual antes de prosseguir")
                } else {
                    validation = true;
                }
            } else {
                setError("Selecione pelo menos uma opção");
            }
        } else {
            let input = document.querySelectorAll(`[name=answer${currentQuestion.id}]`)
            if (input[0].value !== "" && input[0].value !== ' ') {
                validation = true;
            } else {
                setError("Responda a pergunta atual antes de prosseguir");
            }
        }
        if (currentQuestion.action.split(':')[0] === 'REPEAT') {
            let pollElement = document.querySelector(".quizdisplay");
            let selectElements = pollElement.querySelectorAll(".subformQuestion");
            for (let i = 0; i < selectElements.length; i++) {
                if (selectElements[i].value === ' ') {
                    setError("Responda as perguntas atuais antes de prosseguir");
                    return;
                }
            }
        }
        if (validation) {
            setError("");
            let next = position.current + 1;
            if (skip) {
                currentOptions.forEach((option) => {
                    if (option.action !== "" && checkedState[currentQuestion.position][option.position]) {
                        let actionArray = option.action.split(':');
                        if (actionArray[0] === "SKIP_QUESTION") {
                            next = Number(actionArray[1]);
                        }
                    }
                });
            }
            lastPositons.push(position.current);
            setLastPositions(lastPositons);
            position.current = next;


            if (position.current !== finish) {
                if (position.current < finish) setPercentValue(Math.round((position.current * 100) / questions.length));

                let q = questions.find(question => question.position === position.current && question.group === "");
                let o = options.filter(option => option.question === q.id);
                checkSkip(o);
                let action = q.action.split(':');
                if (action[0] === 'REPEAT') {
                    let qs = questions.filter(question => question.group === action[1]);
                    qs.forEach(question => {
                        question.options = options.filter(option => option.question === question.id)
                    });
                    setSubformQuestions(qs);
                    setSubform(true);
                } else {
                    setSubform(false);
                }

                setCurrentQuestion(q);
                setCurrentOptions(o);
            } else {
                setPercentValue(100);
            }

            setDummy(!dummy);
        };
    }
    const previewQuestion = () => {
        setError("");
        if (position.current > 1) {
            position.current = lastPositons.at(-1);
            lastPositons.pop();
            let q = questions.find(question => question.position === position.current && question.group === "");
            let o = options.filter(option => option.question === q.id);
            setLastPositions(lastPositons);
            checkSkip(o);
            let action = q.action.split(':');
            if (action[0] === 'REPEAT') {
                let qs = questions.filter(question => question.group === action[1]);
                qs.forEach(question => {
                    question.options = options.filter(option => option.question === question.id)
                });
                setSubformQuestions(qs);
                setSubform(true);
            } else {
                setSubform(false);
            }
            setCurrentQuestion(q);
            setCurrentOptions(o);
            if (position.current < finish) setPercentValue(Math.round((position.current * 100) / questions.length));
        }
    };
    const start = () => {
        setPercentValue(0);
        let q = questions.find(question => question.position === position.current && question.group === "");
        let o = options.filter(option => option.question === q.id);
        checkSkip(o);
        setCurrentQuestion(q);
        setCurrentOptions(o);
        setSubform(false);
    }
    const checkSkip = (options) => {
        setSkip(false);
        options.forEach((option) => {
            if (option.action !== "") {
                let actionArray = option.action.split(':');
                if (actionArray[0] === "SKIP_QUESTION") {
                    setSkip(true);
                }
            }
        });
    }

    if (questions.length !== 0 && checkedState) {
        if (initial.current === 0) {
            initial.current = 1;
        } else if (initial.current === 1) {
            initial.current = 2;
            start();
        }

        root.style.setProperty('--percentage', String(percentValue + '%'));

        if (currentQuestion.max_answers > 0) {
            var inputType = currentQuestion.max_answers > 1 ? "checkbox" : "radio";
            var numSelect = currentQuestion.max_answers > 1 ? "Selecione no máximo " + currentQuestion.max_answers + " alternativas" : "";
        } else {
            inputType = "text";
        }
        if (position.current === finish) {

            return (
                <div className="Polls">

                    <div className="PollForm">
                        <div className="poll">
                            <div className="poll-title">
                                <h2>
                                    Fim da Pesquisa!
                                </h2>
                                <hr></hr>
                            </div>
                            <div className="poll-progress-bar">
                                <span className="bar">
                                    <span className="progress" />
                                </span>
                                <p className="blue percentage">{percentValue}% concluído</p>
                            </div>
                            <div className="question-title">

                                <h2> Obrigado por sua participação</h2>
                                <h3> Tem certeza que deseja finalizar? </h3>
                            </div>
                            <div className="poll-control-buttons">
                                <div className="poll-control-preview">
                                    <button type="button" onClick={previewQuestion} id={"prev" + currentQuestion.position}>Anterior</button>
                                </div>
                                <div className="poll-control-next-finish">
                                    <button type="button" onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        setIsLoading(true);
                                        handleSubmit(event, questions, options, likertOptions, respondentData).then((result) => {
                                            setIsLoading(false);
                                        });

                                    }}

                                        id={"finish" + currentQuestion.position}
                                        disabled={isLoading}
                                    >
                                        <span>
                                            {isLoading ? <img src={loadingGif} alt="carregando" height="16px" width="16px" /> : "Finalizar"}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="Polls">
                    <div className="PollForm">
                        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" /> */}
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" />
                        {/* <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script> */}
                        <form className="poll">
                            <div className="poll-title">
                                <h2>
                                    {currentQuestion.module_name}
                                </h2>
                                <hr></hr>
                            </div>

                            {String(currentQuestion.module_name).toLowerCase() !== "dados cadastrais" ? (
                                <div className="poll-progress-bar">
                                    <span className="bar">
                                        <span className="progress" />
                                    </span>
                                    <p className="blue percentage">{percentValue}% concluído</p>
                                </div>
                            ) : null}

                            {(() => {

                                if (currentQuestion.description !== "" && currentQuestion.numSelect !== "") {
                                    return (
                                        <div className="question-title">
                                            <h4 className="question-title-title">
                                                {currentQuestion.description}
                                            </h4>
                                            <h3>
                                                {currentQuestion.title}
                                            </h3>
                                            <h5>
                                                {currentQuestion.numSelect}
                                            </h5>
                                        </div>
                                    );
                                } else if (currentQuestion.description !== "") {
                                    return (
                                        <div className="question-title">
                                            <h4 className="question-title-title">
                                                {currentQuestion.description}
                                            </h4>

                                            <h3>
                                                {currentQuestion.title}
                                            </h3>
                                        </div>
                                    );
                                } else if (numSelect !== "") {
                                    return (
                                        <div className="question-title">
                                            <h3>
                                                {currentQuestion.title}
                                            </h3>
                                            <h5>
                                                {numSelect}
                                            </h5>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="question-title">
                                            <h3>
                                                {currentQuestion.title}
                                            </h3>
                                        </div>
                                    );
                                }
                            })()}
                            {error !== "" ? <span className="error"> {error} </span> : null}

                            {(() => {
                                switch (currentQuestion.metric) {
                                    case 1:
                                        return (
                                            <Radio
                                                question={currentQuestion}
                                                options={currentOptions}
                                                likertOptions={likertOptions}
                                                likertScale={likertScale}
                                            />
                                        )

                                    case 2:
                                        switch (inputType) {
                                            case "checkbox":
                                                return (
                                                    <div className="poll-answers">
                                                        <Checkbox
                                                            question={currentQuestion}
                                                            options={currentOptions}
                                                        />
                                                    </div>
                                                )

                                            case "radio":
                                                return (
                                                    <div className="poll-answers">
                                                        <Radio
                                                            question={currentQuestion}
                                                            options={currentOptions}
                                                        />
                                                    </div>
                                                )

                                            default:
                                                break;
                                        }
                                        break;
                                    case 3:
                                        return (
                                            <Text question={currentQuestion} />
                                        )

                                    case 4:
                                        return (
                                            <Text question={currentQuestion} />
                                        )
                                    case 5:
                                        return (
                                            <Select question={currentQuestion} options={currentOptions} />
                                        )
                                    case 6:
                                        return (
                                            <TimeInput question={currentQuestion} />
                                        )
                                    default:
                                        break;
                                }
                            })()}
                            {(() => {
                                if (subform) {
                                    let repeat = document.querySelectorAll(`[name=answer${currentQuestion.id}]`);
                                    return (<SubForm repeat={repeat[0] ? Number(repeat[0].value) : 0} questions={subformQuestions} dummy={dummy} setDummy={setDummy} />)
                                }
                            })()}
                            {(() => {
                                if (position.current > 1) {
                                    return (
                                        <div className="poll-control-buttons">
                                            <div className="poll-control-preview">
                                                <button type="button" onClick={previewQuestion} id={"prev" + currentQuestion.position}>Anterior</button>
                                            </div>
                                            <div className="poll-control-next">
                                                <button type="button" onClick={nextQuestion} id={"prox" + currentQuestion.position}>Próximo</button>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="poll-control-buttons">
                                            <div className="poll-control-next">
                                                <button type="button" onClick={nextQuestion} id={"prox" + currentQuestion.position}>Próximo</button>
                                            </div>
                                        </div>
                                    )
                                }
                            })()}
                        </form>
                    </div >
                </div >
            )
        }
    }
}

export default PollForm;