import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import Polls from './pages/Polls'
import Documents from "./pages/Documents";
import Password from './pages/Password';
import News from "./pages/News";
import Respondent from "./pages/Respondent";
import Researcher from "./pages/Researcher";
import Questionary from "./pages/Questionary";
import PollsHome from "./pages/PollsHome";
import About from "./pages/About";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/pesquisas" element={<Polls />} />
        <Route path="/pesquisa" element={<PollsHome />} />
        <Route path="/documentos" element={<Documents />} />
        <Route path="/noticias" element={<News />} />
        <Route path="/sobrenos" element={<About />} />
        <Route path="/pesquisa/pesquisador/:id" element={<Researcher />} />
        <Route path="/pesquisa/respondente/:id" element={<Respondent />} />
        <Route path="/pesquisa/formulario" element={<Questionary />} />
        <Route path="/usuario/redefinirSenha/:token" element={<Password />} />
      </Routes>
    </BrowserRouter>
  );
}

