/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useFormik } from 'formik'
import * as Yup from 'yup';
import loadingGif from '../../../assets/images/loading.gif'
import './RedefinePassword.css'
import { sendForgotPasswordEmail } from "../../../services/Api";

export default function RedefinePassword({ onRequestLoginClose, onRedefinePasswordClose }) {

    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            resetEmail: "",
        },
        validationSchema: Yup.object({
            resetEmail: Yup.string().email('Digite um e-mail válido').required('Digite um e-mail'),
        }),
        onSubmit: async (e) => {
            setIsLoading(true);

            try {
                sendForgotPasswordEmail({ 
                    plan: 1,
                    email: e.resetEmail 
                }).then((response) => {
                    if (response.data) {
                        if (response.data.statusCode === 250) {
                            setIsLoading(false);
                            alert('Email enviado com sucesso');
                            onRedefinePasswordClose(false);
                            window.location.reload();
                        }
                    }
                    else {
                        if (response.status === 400) {
                            alert("O Email informado não foi encontrado");
                        }
                    }
                })
            }
            catch (err) {
                alert("Ocorreu um erro inesperado. Tente novamente mais tarde");
            }

            setIsLoading(false);
        }
    })

    return (
        <div className="Background-password">
            <div className="RedefineContent">
                <div className="closeBtn">
                    <button type="button" onClick={() => onRedefinePasswordClose(false)} >
                        X
                    </button>
                </div>
                <form className="form-Redefine" onSubmit={formik.handleSubmit}>
                    <h2>Redefinir Senha</h2>
                    <h4>Insira o email cadastrado para redefinir a senha</h4>
                    <input
                        type="text"
                        id="resetEmail"
                        className="form-control"
                        value={formik.values.email}
                        placeholder="E-mail"
                        onChange={formik.handleChange}
                    />

                    <button type="submit" className="btn-submit" disabled={isLoading}>
                        <span>
                            {isLoading ? <img src={loadingGif} alt="carregando" height="16px" width="16px" /> : "Enviar"}
                        </span>
                    </button>

                    <hr />
                </form>
            </div>
        </div >
    );
};