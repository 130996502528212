import './ProductsContent.css'



import { FaFilePdf, FaFileWord, FaFileImage, FaFileCsv, FaFileExcel, FaFile, FaChevronUp } from 'react-icons/fa';


function ProductsContent() {
    const fileTypeToIcon = {
        pdf: FaFilePdf,
        doc: FaFileWord,
        docx: FaFileWord,
        jpg: FaFileImage,
        png: FaFileImage,
        csv: FaFileCsv,
        xls: FaFileExcel,
        xlsx: FaFileExcel,
    };
    const apresentacao1 = 'https://drive.google.com/file/d/1Mn26kXz4A9Wji7P3Z1I_W2Mrxy2_nBf2/view?usp=drive_link';
    const apresentacao2 = 'https://drive.google.com/file/d/1jXh3o41xDLyF4n3RVNWnNfZgNCzmoa2n/view?usp=drive_link';

    const produto1 = 'https://drive.google.com/file/d/1RX5bxaYKodi8J2hXmdUcqcwOQcja93sK/view?usp=drive_link';
    const produto2 = 'https://drive.google.com/file/d/1SStlu3qSTo35Et24V_kUIyVh4CoLkkxt/view?usp=drive_link';
    const produto3R1 = 'https://drive.google.com/file/d/1_1Co4-cHcqB7peXxDfpngAL8Ihk6fWmF/view?usp=drive_link';
    const produto3R2 = 'https://drive.google.com/file/d/1YUuLezHOnNpaHT2pNPE5E5UVGZ5Z_3zA/view?usp=drive_link';
    const produto3R3 = 'https://drive.google.com/file/d/16oy-D2nrRJe00c33z2nsNn-66Wbv2s-M/view?usp=drive_link';
    const produto4 = 'https://drive.google.com/file/d/1rCF3YmTtejF85fIeA4-Iar-E4DADVl5x/view?usp=drive_link';
    const produto5 = 'https://drive.google.com/file/d/1xTx84dmCj2muwron5U7wD1uMslNZ92Jk/view?usp=drive_link';
    const produto6 = 'https://drive.google.com/file/d/1m1THtmo5hXLsHs_RC7Z9udgs6CmT1eQR/view?usp=drive_link';
    const produto7 = 'https://drive.google.com/file/d/1thOmjPFHBSW_mTa3elDAIeMPUB_yV4eb/view?usp=drive_link';
    const produto8 = 'https://drive.google.com/file/d/1o-RrMP7-jls5eXNiMns4HAXKZNsE5mIJ/view?usp=drive_link';

    const anexo1P3 = 'https://drive.google.com/file/d/1LUVCXLNj7FrOKB-Inh3jFMzGo1XAr8Ez/view?usp=drive_link';
    const anexo2P3 = 'https://drive.google.com/file/d/13bitjtXaodlR7mQAP7MW45rQs_fwQPtg/view?usp=drive_link';
    const anexo3P3 = 'https://drive.google.com/file/d/15UxiOdGwtQlRbUxS3GLmx-vRTIEjiiyc/view?usp=drive_link';

    const anexo1P4 = 'https://drive.google.com/file/d/1nL1gvPgcSove39AjsQHPOBd65UlmyuFd/view?usp=drive_link';

    const anexo1P5 = 'https://drive.google.com/file/d/1AzdNXx-aH6MxzdOACkh2Oi9yUnDtvdfl/view?usp=drive_link';
    const anexo2P5 = 'https://drive.google.com/file/d/1mMapof6arcQNrYPpCDPPHdYcBZUVjHx1/view?usp=drive_link';
    const anexo3P5 = 'https://drive.google.com/file/d/1tfNpbu1i4BPltwdUIOY4e0oF6JF12qWj/view?usp=drive_link';
    const anexo4P5 = 'https://drive.google.com/file/d/1gCgPYQG5-19jUBPjGS6JZ2QBMBlucSSu/view?usp=drive_link';

    const anexo1P8 = 'https://drive.google.com/file/d/1XFXlIuf2WJEO3yR54qafFSB0dM8-YDMm/view?usp=drive_link';
    const anexo2P8 = 'https://drive.google.com/file/d/1Zyl65O1zv9D5_3Ca0kqpo1t3vJrED0sc/view?usp=drive_link';
    const anexo3P8 = 'https://drive.google.com/file/d/14hnPlCeiROuQKHXgeQM4MDtPXeq2DJNk/view?usp=drive_link';
    const anexo4P8 = 'https://drive.google.com/file/d/1h4FAQuSSznMuP8fZ7_7D7T1GybniG8U8/view?usp=drive_link';
    const anexo5P8 = 'https://drive.google.com/file/d/10oQtCJi5D6aVkvwOAI46M90vLUB1K3WE/view?usp=drive_link';
    const anexo6P8 = 'https://drive.google.com/file/d/1N8B9p_FAWah05Rxk8qU7yd_PJcq_TxSE/view?usp=drive_link';
    const anexo7P8 = 'https://drive.google.com/file/d/14U4gHoybpmrvA_H96xrkvQ2F3oHzL4Po/view?usp=drive_link';
    const anexo8P8 = 'https://drive.google.com/file/d/1Rg-OKe23v7fZCXvl_IMlao1JhUJfcNww/view?usp=drive_link';
    const anexo9P8 = 'https://drive.google.com/file/d/1UeP_h1S2VevOU-bUm8b2vvyEgPDr4vH8/view?usp=drive_link';
    const anexo10P8 = 'https://drive.google.com/file/d/1sYuVtuEm_jiz7eKI_dsbzJl1RykER4Sa/view?usp=drive_link';
    const products = [
        {
            cardId: 1,
            cardName: "Produto 1 - Planejamento executivo",
            files: [
                {
                    path: produto1,
                    filename: "2023.11.10_P1_VO_PMUMI.pdf",
                    extension: "pdf"
                }
            ]
        },
        {
            cardId: 2,
            cardName: "Produto 2 - Plano de Comunicação e Participação Social",
            files: [
                {
                    path: produto2,
                    filename: "2023.11.10_P2_VO_PMUMI.pdf",
                    extension: "pdf"
                }
            ]
        },
        {
            cardId: 3,
            cardName: "Produto 3 - Diagnóstico",
            files: [
                {
                    path: produto3R1,
                    filename: "2023.11.10_P3_R1_VO_PMUMI.pdf",
                    extension: "pdf"
                },
                {
                    path: produto3R2,
                    filename: "2023.11.10_P3_R2_VO_PMUMI.pdf",
                    extension: "pdf"
                },
                {
                    path: produto3R3,
                    filename: "2023.11.10_P3_R3_VO_PMUMI.pdf",
                    extension: "pdf"
                },
                {
                    path: anexo1P3,
                    filename: "ANEXO I_ P3R2_Formulario_qualiônibus.pdf",
                    extension: "pdf"
                },
                {
                    path: anexo2P3,
                    filename: "ANEXO II_P3R2_Pesquisa_velocidade_retardamento.pdf",
                    extension: "pdf"
                },
                {
                    path: anexo3P3,
                    filename: "ANEXO III_P3R2_Resultados contagens e pesquisa OD veicular.pdf",
                    extension: "pdf"
                },
            ]
        },
        {
            cardId: 4,
            cardName: "Produto 4 - Prognóstico",
            files: [
                {
                    path: produto4,
                    filename: "2023.10.30_P4_VP_PMUMI.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo1P4,
                    filename: "2023.11.06_ANEXO_P4_PMUMI.pdf",
                    extension: "pdf",
                }
            ]
        },
        {
            cardId: 5,
            cardName: "Produto 5 - Detalhamento das alternativas e diretrizes de trânsito e transportes",
            files: [
                {
                    path: produto5,
                    filename: "2023.11.13_P5_VP_PMUMI.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo1P5,
                    filename: "2023.10.24.ANEXO I_P5_VP_PMUMI.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo2P5,
                    filename: "2023.10.24.ANEXO II_P5_VP_PMUMI.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo3P5,
                    filename: "2023.10.24.ANEXO III_P5_VP_PMUMI.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo4P5,
                    filename: "2023.10.24.ANEXO IV_P5_VP_PMUMI.pdf",
                    extension: "pdf",
                },
            ]
        },
        {
            cardId: 6,
            cardName: "Produto 6 - Monitoramento e Instrumentos de Gestão",
            files: [
                {
                    path: produto6,
                    filename: "2023.11.21_P6_VP_PMUMI.pdf",
                    extension: "pdf",
                },

            ]
        },
        {
            cardId: 7,
            cardName: "Produto 7 - Resumo Executivo",
            files: [
                {
                    path: produto7,
                    filename: "2023.11.13.P7_VP_PMUMI.pdf",
                    extension: "pdf",
                },
            ]
        },
        {
            cardId: 8,
            cardName: "Produto 8 - Minuta de Lei",
            files: [
                {
                    path: produto8,
                    filename: "2023.11.13_P8_VP_PMUMI.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo1P8,
                    filename: "ANEXO I.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo2P8,
                    filename: "ANEXO II.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo3P8,
                    filename: "ANEXO III.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo4P8,
                    filename: "ANEXO IV.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo5P8,
                    filename: "ANEXO V.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo6P8,
                    filename: "ANEXO VI.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo7P8,
                    filename: "ANEXO VII.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo8P8,
                    filename: "ANEXO VIII.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo9P8,
                    filename: "ANEXO IX.pdf",
                    extension: "pdf",
                },
                {
                    path: anexo10P8,
                    filename: "ANEXO X.pdf",
                    extension: "pdf",
                },
            ]
        },
        {
            cardId: 9,
            cardName: "Apresentações",
            files: [
                {
                    path: apresentacao1,
                    filename: "2ª rodada audiências.pdf",
                    extension: "pdf",
                },
                {
                    path: apresentacao2,
                    filename: "_Apresentação diagnóstico - PMUMI (Audiência Pública).pdf",
                    extension: "pdf",
                }
            ]
        }
    ]


    const handleCardChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.checked = !e.target.checked;
        let cardId = e.target.id.split('-')[1];
        const cardList = document.getElementById('cardList-' + cardId);
        const cardContent = document.getElementById('cardContent-' + cardId);
        const cardChevron = document.getElementById('cardChevron-' + cardId);

        let currentRotation = parseInt(cardChevron.style.transform.replace('rotate(', '').replace('deg)', ''), 10) || 0;

        if (cardContent.style.maxHeight === '100vh') {
            cardContent.style.maxHeight = '0vh';
            cardChevron.style.transform = `rotate(${currentRotation - 180}deg)`;
            cardList.style.borderRadius = "10px";
            cardList.style.boxShadow = "0px 6px 2px 0px rgb(150, 140, 140)";
        } else {
            cardList.style.borderRadius = "10px 10px 0px 0px";
            cardContent.style.maxHeight = '100vh';
            cardChevron.style.transform = `rotate(${currentRotation + 180}deg)`;
            cardList.style.boxShadow = "0px 0px 0px 0px ";
        }


    };

    return (
        <div className='productsContent'>
            <div className='productsTitle'>
                <h1>DOCUMENTOS OFICIAIS</h1>
                <h2>Confira o conteúdo elaborado até o momento do Plano!</h2>
                {/* <p>Tenha acesso a todos os documentos oficiais (Versões prévias, Justificadas, Oficiais,
                    Memoriais Técnicos e Produtos) do Plano de Mobilidade de Itajubá.</p> */}
            </div>
            <div className="center">
                {(() => {
                    if (products) {
                        return products.map((product) => {

                            return (
                                <div key={product.cardId} className="cardProduct">
                                    <label className="cardList" id={"cardList-" + product.cardId}>
                                        <div className="cardTitle">
                                            <h2>{product.cardName}</h2>
                                        </div>
                                        <FaChevronUp className="cardChevron" id={"cardChevron-" + product.cardId} />
                                        <input type="checkbox" name="card-toggle" id={"cardToggle-" + product.cardId} onChange={(e) => handleCardChange(e)} hidden defaultChecked="true" />

                                    </label>
                                    <div className="cardContent" id={"cardContent-" + product.cardId}>
                                        <ul className='fileList'>
                                            {(() => {
                                                if (product.files) {
                                                    return product.files.map(({ path, filename, extension }) => {
                                                        const Icon = fileTypeToIcon[extension] || FaFile;
                                                        return (
                                                            <li className='fileListItem'>
                                                                {Icon && <Icon />}
                                                                <div className="fileTitle">
                                                                    <a href={path} target='_blank' rel="noreferrer" >
                                                                        <h4>{filename}</h4>
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        )
                                                    });
                                                }
                                            })()}
                                        </ul>
                                    </div>

                                </div>
                            )
                        })
                    }
                })()}
            </div>
        </div>
    );
}

export default ProductsContent;