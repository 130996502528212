import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Login from '../User/LoginForm/Login';
import Register from '../User/RegisterForm/Register';
import Redefine from '../User/RedefinePassword/RedefinePassword';

import './Navbar.css';
import { HiChevronDown } from "react-icons/hi";
import dac from '../../assets/images/DAC_1.png';
import prefeitura from '../../assets/images/prefeitura-itajuba.png';
import { useCookies } from 'react-cookie';
import { useAuth } from '../../contexts/AuthContext';

import DownloadFile from '../DownloadFile/DownloadFile';

function Navbar() {
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isRedefineOpen, setIsRedefineOpen] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [dummy, setDummy] = useState(false);
    const [cookies] = useCookies(['dac-auth-user']);

    const { signOut } = useAuth()


    return (
        <>
            <div className={toggle ? 'Navbar-collapsed' : 'Navbar'}>
                <div className='navbar-menu'>
                    <div className="navbar--logo">
                        <img className='logo-prefeitura' src={prefeitura} alt="Prefeitura de Itajubá" />
                        <img className='logo-plano' src={dac} alt="DAC - Engenharia" />
                    </div>


                    <div className="navbar--features">
                        <ul className='list'>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/pesquisas">Pesquisas</Link></li>
                            <li><Link to="/documentos">Documentos</Link></li>
                            <li><Link to="/noticias">Notícias</Link></li>
                            <li><Link to="/sobrenos">Sobre Nós</Link></li>
                        </ul>
                        {cookies['dac-auth-role'] === 'administrador' ? <DownloadFile /> : null}
                    </div>
                    <div className='navbar--login'>
                        {/* {cookies['dac-auth-user'] === undefined ? (
                            <div className='navbar--login-buttons'>
                                <button type="button" className='nav-button' onClick={() => { setIsLoginOpen(true) }} >Entrar</button>
                                <button type="button" onClick={() => { setIsRegisterOpen(true) }}>Cadastre-se</button>
                            </div>
                        ) : (
                            <div className="nav-user">
                                <p className="nav-name">Olá <b>{cookies['dac-auth-user'].split(' ')[0]}</b>!</p>
                                <button><p className="link-blue" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    signOut();
                                    setDummy(!dummy);
                                }}>Sair</p></button>

                            </div>
                        )} */}
                    </div>

                    <div className='toggler'>
                        <label className='toggler-icon' htmlFor="toggler"><HiChevronDown htmlFor="toggler"></HiChevronDown></label>
                        <input
                            type="checkbox"
                            className='toggler-checkbox'
                            id="toggler"
                            name="toggler"
                            defaultChecked={toggle}
                            onChange={(e) => {
                                //e.preventDefault();
                                e.stopPropagation();
                                setToggle(!toggle);
                                setDummy(!dummy);
                            }}
                        />
                    </div>


                    {isLoginOpen && <Login onRequestLoginClose={setIsLoginOpen} onRequestRegisterClose={setIsRegisterOpen} onRedefinePasswordClose={setIsRedefineOpen} />}
                    {isRegisterOpen && <Register onRequestLoginClose={setIsLoginOpen} onRequestRegisterClose={setIsRegisterOpen} />}
                    {isRedefineOpen && <Redefine onRequestLoginClose={setIsLoginOpen} onRedefinePasswordClose={setIsRedefineOpen} />}
                </div>

            </div>
        </>
    )
}

export default Navbar;