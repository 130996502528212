import { useState } from 'react';
import { AiFillClockCircle, AiOutlineClockCircle } from 'react-icons/ai';
import { useAnswer } from '../../../contexts/AnswerContext';
import './TimePicker.css'

export default function TimeInput(data) {

    const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
    const minutes = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']

    const { question, _class } = data;

    const { answers, handleTextInputChange } = useAnswer();

    const [clock, setClock] = useState(false);

    if (answers[question.id] === undefined) {
        answers[question.id] = '';
    }

    const handleTimeInputChange = (option, val) => {
        let newTime, time;
        time = answers[question.id].split(':');
        if (!time[0]) {
            time = ['00', '00']
        }
        if (option === 'hours') {
            newTime = val + ':' + time[1];
        } else {
            newTime = time[0] + ':' + val;
        }
        handleTextInputChange({ target: { id: question.id, value: newTime } })
    }

    return (
        <div className="poll-answers-text">
            <div className="timePicker">
                <input
                    type='text'
                    className={_class ? 'timePickerInput ' + _class : 'timePickerInput'}
                    name={'answer' + question.id}
                    maxLength={5}
                    placeholder='00:00'
                    value={answers[question.id]}
                    readOnly
                />
                <label
                    className='clockToggle'
                    htmlFor='clockCheck'
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setClock(!clock);
                    }}>

                    <input type='checkbox' className='clockCheck' />
                    {!clock ? <AiOutlineClockCircle size={25} /> : <AiFillClockCircle size={25} />}
                </label>
            </div>
            <div className='timeList' style={(clock) ? { maxHeight: '33vh' } : { maxHeight: '0px' }}>
                <ul className='hoursList' style={(clock) ? { maxHeight: '32vh' } : { visibility: 'hidden' }}>
                    {(() => {
                        return hours.map((h) => {
                            return (
                                <li><button onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleTimeInputChange('hours', h); }}>{h}</button></li>
                            )
                        })
                    })()}
                </ul>
                <ul className='minutesList' style={(clock) ? { maxHeight: '32vh' } : { visibility: 'hidden' }}>
                    {(() => {
                        return minutes.map((m) => {
                            return (
                                <li><button onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleTimeInputChange('minutes', m); }}>{m}</button></li>
                            )
                        })
                    })()}
                </ul>
            </div>
        </div>
    )


}