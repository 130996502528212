import { createContext, useEffect, useContext } from "react";
import { useCookies } from 'react-cookie'
import { api } from '../services/Api';
import { AxiosError } from "axios";

export const AuthContext = createContext({});

export function AuthProvider(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['dac-auth-token', 'dac-auth-user', 'dac-auth-role']);

    useEffect(() => {
        api.defaults.headers['Authorization'] = `Bearer ${cookies["dac-auth-token"]}`;
    }, [cookies])

    const signOut = () => {
        api.defaults.headers['authorization'] = '';
        removeCookie('dac-auth-token', {
            path: '/'
        });
        removeCookie('dac-auth-user', {
            path: '/'
        });
        removeCookie('dac-auth-role', {
            path: '/'
        });
    }


    const signIn = async (email, password) => {
        try {

            const response = await api.post('/auth/signin', {
                email,
                password
            })

            const { token, name, role } = response.data;

            setCookie('dac-auth-token', token, {
                maxAge: 60 * 60 * 24 * 10,
                path: '/',
            });
            setCookie('dac-auth-user', name, {
                maxAge: 60 * 60 * 24 * 10,
                path: '/',
            });
            setCookie('dac-auth-role', role, {
                maxAge: 60 * 60 * 24 * 10,
                path: '/',
            })


            api.defaults.headers['Authorization'] = `Bearer ${token}`;

        } catch (err) {
            if (err instanceof AxiosError) {
                return err.response.data;
            }
        }
        return {
            statusCode: 200,
            body: "success"
        };
    }

    const signUp = async ({ name, cpf, email, phone, password }) => {
        try {

            const response = await api.post('/users/signup', {
                name,
                cpf,
                email,
                phone,
                password
            })

            setCookie('dac-auth-token', response.data.token, {
                maxAge: 60 * 60 * 24 * 10,
                path: '/',
            });
            setCookie('dac-auth-user', response.data.user.name, {
                maxAge: 60 * 60 * 24 * 10,
                path: '/',
            });
            setCookie('dac-auth-role', response.data.user.role, {
                maxAge: 60 * 60 * 24 * 10,
                path: '/',
            });


            api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;


        } catch (err) {
            if (err instanceof AxiosError) {

                return err.response.data;
            }
        }
        return {
            statusCode: 201,
            body: "success"
        };
    }

    return (
        <AuthContext.Provider value={{ signIn, signOut, signUp }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}