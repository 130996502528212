import { Formik, Form, ErrorMessage } from 'formik';
import PasswordField from '../../Inputs/Password/Password';
import * as Yup from 'yup'
import './NewPassword.css';
import { UpdatePassword } from '../../../services/Api';
import { useNavigate } from 'react-router-dom';

function NewPassword({ value }) {
    const navigate = useNavigate();

    return (
        <div className='NewPassword'>
            <Formik
                initialValues={{ password: '', confirm_password: '' }}
                validationSchema={
                    Yup.object({
                        password: Yup.string().required('Informe a nova senha')
                            .test('length', 'A Nova senha deve ter no mínimo 8 caracteres', (val) => val && val.length >= 8),
                        confirm_password: Yup.string().required('Repita a nova senha')
                            .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
                    })

                }
                onSubmit={(e) => {
                    const data = {
                        token: value.token,
                        password: e.password,
                        confirm_password: e.confirm_password
                    }
                    UpdatePassword(data).then((response) => {
                        if (response.status === 200) {
                            alert('Senha alterada com sucesso');
                            navigate('/');
                        } else {
                            alert('Ocorreu um erro, tente novamente mais tarde')
                        }
                    })
                }}  >
                {(props) => (
                    <Form className='card-senha'>
                        <h2>Cadastrar Nova Senha</h2>
                        <div className='input-senha'>
                            <label>Insira a senha</label>
                            <PasswordField
                                values={{
                                    placeholder: 'Digite a nova senha',
                                    name: 'password',
                                    onChange: props.handleChange,
                                    onBlur: props.handleBlur,
                                    value: props.values.name,
                                    style: props.errors.password && props.touched.password ? { borderColor: '#ff0000' } : { borderColor: 'rgba(0, 0, 0, 0.575)' }
                                }}
                            />
                            <div className='error'>
                                <ErrorMessage name='password' />
                            </div>
                        </div>
                        <div className='input-senha'>
                            <label>Insira a senha novamente</label>
                            <PasswordField
                                values={{
                                    placeholder: 'Repita a nova senha',
                                    name: 'confirm_password',
                                    onChange: props.handleChange,
                                    onBlur: props.handleBlur,
                                    value: props.values.name,
                                    style: props.errors.password && props.touched.password ? { borderColor: '#ff0000' } : { borderColor: 'rgba(0, 0, 0, 0.575)' }
                                }}

                            />
                            <div className='error'>
                                <ErrorMessage name='confirm_password' />
                            </div>

                        </div>
                        <button type='submit'>Salvar</button>
                    </Form>
                )}
            </Formik>
        </div >
    );
}

export default NewPassword;
