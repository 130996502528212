import './Banner.css'
import title from '../../../assets/images/Plano_de_mobilidade_branco.png'
import noticia from '../../../assets/images/noticia_2.png'
import noticia3 from '../../../assets/images/noticia_3_.png';
import noticia4 from '../../../assets/images/noticia_4_.png';
import noticia6 from '../../../assets/images/noticia_6.png';

import pesquisaonibus from '../../../assets/images/participepesquisa.png'
import { Link } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const slides = [
    {
        title: "plano-mobilidade",
        control:
            <div className="btn">
                <Link to="/documentos">Ver Resultados</Link>
            </div>,
        image: title,
        class: "plano-logo",
    },
    {
        title: "noticia6",
        image: noticia6,
        control: <div className="btn">
            <Link to="/noticias">Saiba mais</Link>
        </div>,
    },
    {
        title: "noticia5",
        image: noticia3,
        control: <div className="btn">
            <Link to="/noticias">Saiba mais</Link>
        </div>,
    },
    {
        title: "noticia4",
        image: noticia4,
        control: <div className="btn">
            <Link to="/noticias">Saiba mais</Link>
        </div>,
    },
    // {
    //     title: "noticia3",
    //     image: noticia3,
    //     control: <div className="btn">
    //         <Link to="/noticias">Saiba mais</Link>
    //     </div>,
    // },
    {
        title: "noticia2",
        image: noticia,
        control: <div className="btn">
            <Link to="/noticias">Saiba mais</Link>
        </div>,
    },
    {
        title: "noticia1",
        image: pesquisaonibus,
        control: <div className="btn">
            <Link to="/noticias">Saiba mais</Link>
        </div>,

    }
];



function Banner() {

    return (
        <div className='Banner'>
            <div className='center'>
                <div className='banner-items'>

                    <Carousel
                        autoPlay={true}
                        interval={4500}
                        swipeable={true}
                        emulateTouch={true}
                        infiniteLoop={true}
                        showArrows={true}
                        showStatus={false}
                        showThumbs={false}
                    >
                        {slides.map((slide) => (
                            <div key={slide.title}>
                                <div className={"image-wrapper " + slide.class}>
                                    <img src={slide.image} alt='Plano de mobilidade de Itajubá' />
                                    {slide.control}
                                </div>
                            </div>
                        ))}
                    </Carousel>

                </div>
            </div>
        </div>
    )
}

export default Banner;