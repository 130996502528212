import React from 'react';
import '../Global.css';
import PollForm from '../components/Polls/PollForm/PollForm';

function Questionary() {

    return (
        <div>
            <div>
                {<PollForm />}
            </div>
        </div>
    )
}

export default Questionary;
