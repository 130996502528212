import '../Global.css';
import NewPassword from '../components/User/NewPassword/NewPassword'
import Spinner from '../components/spinner/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { validateToken } from '../services/Api';
import { useEffect, useRef, useState } from 'react';

function Password() {
    const [isLoading, setIsLoading] = useState(false);
    const validated = useRef(false);
    const navigate = useNavigate();
    const token = useParams();

    useEffect(() => {
        if (!validated.current) {
            if (!token) {
                navigate('/');
            } else {
                setIsLoading(true);
                validateToken(token).then((response) => {
                    if (response.status !== 200) {
                        alert('Token Invalido')
                        navigate('/')
                    } else if (response.status === 200) {
                        validated.current = true;
                        setIsLoading(false);
                    }
                })
            }
            setIsLoading(false);
        }
    }, [navigate, token])


    return (
        <>
            {validated.current && isLoading ? (
                <Spinner props={{ customText: 'Autenticando' }} />
            ) : (
                <NewPassword value={token} />
            )}
        </>
    );
}

export default Password;