import { createContext, useContext, useState } from "react";
import { useCookies } from 'react-cookie'

export const PermissionContext = createContext({})

export function PermissionProvider(props) {
    const [cookies] = useCookies(['dac-auth-role']);

    const [levels,] = useState(
        [
            {

                level: 0,
                role: 'respondente'
            },
            {
                level: 1,
                role: 'pesquisador'
            },
            {
                level: 2,
                role: 'administrador'
            }
        ]
    );

    const isAuthenticated = () => {
        if (cookies['dac-auth-role'] && cookies['dac-auth-role'] !== "") {
            return true;
        } else {
            return false;
        }
    }


    const isPermitted = (permission) => {

        const level = levels.find(item =>
            item.role === cookies['dac-auth-role']
        );

        if (level !== undefined && level.level >= permission) {

            return true;
        } else {
            return false;
        }
    }

    const isPermittedResearcher = () => {
        return isPermitted(1);
    }

    return (
        <PermissionContext.Provider value={{ isPermittedResearcher, isAuthenticated }}>
            {props.children}
        </PermissionContext.Provider>
    )
}

export function usePermission() {
    return useContext(PermissionContext);
}