import './Social.css'

function Social() {
    return (
        <div className='Social' id='social'>
            <div className="social-participation">
                <div className='social--text'>
                    <h2>Participação Social</h2>
                    <p>A participação social é de grande importância durante todo o processo de construção do
                        Plano de Mobilidade, sendo garantida na legislação como parte de uma gestão democrática.
                        Através da participação social é possível compreender a realidade vivida na cidade pela
                        população e entender seus desejos e necessidades de mobilidade. A população pode participar do
                        Plano por meio das audiências públicas, espaços para comentários e sugestões no site e
                        respondendo as pesquisas de opinião. São três pesquisas disponíveis: pesquisa de satisfação
                        com o transporte coletivo, pesquisa modos sustentáveis e pesquisa Origem e Destino.
                        A pesquisa modos sustentáveis busca entender as condições para caminhar, andar de bicicleta
                        e usar o transporte coletivo oferecidas no município.
                        A Pesquisa Origem e Destino busca entender todas as viagens realizadas pelo entrevistado
                        no seu dia a dia. Quais os motivos das viagens, como trabalho e estudo, os modos de
                        transporte utilizados, os horários e tempo de viagem, as dificuldades encontradas, entre outros.
                        Todas as pesquisas estão disponíveis no site. Participe! Sua opinião é muito importante
                        na construção do futuro da nossa cidade!
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Social;