import './NewsContent.css'

import imagem1 from '../../assets/images/pesquisaonibus.png'
import imagem2 from '../../assets/images/apresentacao.png'
import imagem3 from '../../assets/images/noticia_3.png'
import imagem4 from '../../assets/images/noticia_4.png'
import imagem5 from '../../assets/images/noticia_5.png'
import imagem6 from '../../assets/images/noticia_6.jpeg'

function NewsContent() {
    return (
        <div className='NewsContent'>
            <div className='center'>
                <div className='news-title'>
                    <h1>NOTÍCIAS</h1>
                    <h3>Fique por dentro do que acontece em todas as fases do Plano de Mobilidade de Itajubá.</h3>
                </div>

                <div className='news-items'>

                    {json.slice().reverse().map((item, index) => (
                        <div key={index} className='news-cards'>

                            <div className='news-card news-step-3'>

                                <img src={item.img} alt={item.title} />
                                <h4>
                                    {item.title}
                                </h4>
                                {/* <h3>{item.date}</h3> */}

                                <p dangerouslySetInnerHTML={{ __html: item.content }} />

                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
}

export default NewsContent;

const json = [

    {
        "id": 1,
        "title": "PARTICIPE DA PESQUISA EMBARCADA DO TRANSPORTE COLETIVO!",
        "content": "\n A pesquisa de opinião tem por objetivo conhecer o nível de satisfação da população com o serviço de transporte público coletivo de Itajubá. Ela é realizada por meio de entrevistas com os usuários do transporte coletivo enquanto realizam suas viagens! \n \n A pesquisa embarcada do transporte coletivo de Itajubá foi baseada no método da Pesquisa de Satisfação QualiÔnibus do WRI Brasil. O questionário utilizado na pesquisa em Itajubá é o módulo básico e possui 4 seções: Perfil de uso, Satisfação, Perguntas complementares e Perfil do cliente. \n\nO módulo básico avalia 16 fatores de qualidade, sendo 15 aplicáveis no município: Acesso ao transporte; Disponibilidade do serviço; Rapidez do deslocamento; Confiabilidade; Integração; Conforto nos pontos de ônibus, terminal rodoviário e nos ônibus; Atendimento ao cliente; Informação ao cliente; Segurança pública e no trânsito; Exposição ao ruído e a poluição; Forma de pagamento e Gasto. \n \nConfira as linhas de ônibus onde serão realizadas as pesquisas: \n\n➡️ Jd das Nações x Santa Rosa (via rod.)\n➡️ Rebourgeon x Medicina (via Piedade)\n➡️ N. Horizonte x Santa Rosa (via Varginha)\n➡️ Santa Rosa x Rebourgeon (via rod.)\n➡️ Jd das Colinas x Santa Rosa (via Varginha)\n➡️ Santa Rosa x N. Horizonte (via rod.)\n➡️ Goiabal x Rodoviária (via Ano Bom)\n➡️ Rebourgeon x Santa Rosa (via Varginha)\n➡️ Rebourgeon x Cruzeiro (via Santa Luzia)\n➡️ Cruzeiro x Cafona (via Jd das Colinas)\n➡️ Mercado x Gerivá (via Vila Isabel)\n➡️ Mercado x Freire (via São Pedro) \n➡️ Rio Manso x Mercado (via Varginha) \n\nEssa e outras pesquisas podem ser respondidas através do site do Plano de Mobilidade Urbana na aba Pesquisas.\n\nParticipe! Sua opinião é muito importante para qualificar o serviço de transporte coletivo e ajudar a melhorar as condições de deslocamento por ônibus em Itajubá!",
        "img": `${imagem1}`,
        "date": "26/04/2023"
    },
    {
        "id": 2,
        "title": "SEMINÁRIO DA CIDADE",
        "content": "\n O Seminário da Cidade, primeiro evento informativo sobre o Plano de Mobilidade Urbana de Itajubá, ocorreu no dia 24 de abril de 2023 (segunda-feira), às 19h no Teatro Municipal de Itajubá. O evento contou com a presença do Prefeito da cidade Christian Gonçalves e do Vice-Prefeito Dr. Nilo Baracho que realizaram a abertura do evento. \n\nEm seguida, os responsáveis técnicos pelo projeto, que está sendo elaborado pela empresa Dac Engenharia, explicaram para a população o que é o Plano de Mobilidade Urbana, os princípios, diretrizes e objetivos previstos em lei para a realização do Plano e a metodologia e importância da participação social na elaboração. \n\nA apresentação, que está disponível para download, traz informações sobre o planejamento executivo com todas as etapas e produtos que serão entregues ao decorrer de sua elaboração, o cronograma, os agentes envolvidos no processo, os meios de divulgação do Plano e a setorização da cidade com os pontos de apoio onde serão realizadas as rodadas de audiências públicas. Também foram apresentadas todas as pesquisas que serão realizadas para a etapa de diagnóstico e a importância da mobilização da população para responder as pesquisas com o propósito de elaborar um diagnóstico que seja mais fiel à realidade vivida pela população. \n\nPor fim, o Secretário de Defesa Social Massoud Nassar Neto fez o encerramento e houve um espaço aberto para que as pessoas presentes no evento pudessem fazer sugestões e perguntas referentes ao que foi apresentado. Foi destacada, mais uma vez, a importância da participação social no decorrer de todo o processo de elaboração do Plano, através das mídias, site, pesquisas de opinião e audiências públicas, onde toda a população poderá ser ouvida e suas sugestões poderão colaborar para a construção do Plano.",
        "img": `${imagem2}`,
        "date": "27/04/2023"
    },
      {
        "id": 3,
        "title": "PARTICIPE DAS AUDIÊNCIAS PÚBLICAS DO PLANO DE MOBILIDADE URBNA DE ITAJUBÁ!",
        "content": "\n Por iniciativa da Prefeitura Municipal, o plano visa identificar problemas e propor melhorias para a Mobilidade Urbana, além de planejar o desenvolvimento de uma Itajubá mais sustentável.\n\nPara garantir a participação social na realização do Plano, serão realizadas 11 audiências públicas com o objetivo de que todos os munícipes participem de sua construção e validação. Neste espaço, você poderá dar suas sugestões de melhorias e acompanhar como o Plano está sendo elaborado.\n\nConfira as datas e locais das audiências: \n➡️ 28/08 (18h): Comunidade Santa Rita de Cássia (Açude)\n➡️ 28/08 (18h): E.M. Olímpio José Joaquim\n➡️ 28/08 (17h30): E.M. Francisco Pereira Coutinho\n➡️ 29/08 (18h): E.E. Major João Pereira\n➡️ 29/08 (18h): E.M. Francisco Florêncio da Silva\n➡️    29/08 (16h): Associação do Bairro São Pedro\n➡️    30/08 (18h): E.E. Florival Xavier\n➡️    30/08 (18h): E.M. Padre Donato\n➡️    30/08 (18h): APAE Rural\n➡️    31/08 (19h): Teatro Municipal\n➡️    01/09 (19h): E.M. Alcides Faria\n\nConfira o conteúdo já elaborado aqui no site e acesse também nossas redes sociais e fique por dentro de todas as novidades!",
        "img": `${imagem3}`,
        "date": "15/08/2023"
    },
    {
        "id": 4,
        "title": "1º RODADA DE AUDIÊNCIAS PÚBLICAS DO PLANO DE MOBILIDADE URBANA DE ITAJUBÁ",
        "content": "\n A 1º rodada de audiências públicas do Plano de Mobilidade Urbana de Itajubá aconteceu dos dias 28 de agosto à 1 de setembro, em 11 locais espalhados pelo município. A equipe da DAC Engenharia fez a apresentação do diagnóstico do Plano, onde foram mostrados os dados coletados no município dos modos e serviços de transporte, infraestrutura e todas as pesquisas que foram realizadas no transporte coletivo, nas principais vias e com a população.\n\nDepois de cada apresentação foram abertos os espaços para os debates sobre a mobilidade urbana no município. A população que participou das audiências fez suas contribuições, tirando dúvidas, fornecendo informações adicionais e sugestões.\n\nForam apontadas questões quanto ao transporte coletivo, atendimento da área rural, novas linhas e horários, necessidade de acessibilidade nas ruas e calçadas, melhorias de segurança, entre outros. \n\nA partir das sugestões recebidas serão feitos ajustes para dar continuidade ao Plano e a elaboração do prognóstico, onde serão realizadas modelagens dos cenários futuros e a proposição de alternativas, programas e projetos, cujo objetivo é a melhoria das condições da mobilidade urbana de Itajubá. \n\nQuer continuar participando ativamente da construção do Plano? Confia todo o conteúdo já elaborado na aba “Documentos” e entre em contato aqui pelo nosso site!! ",
        "img": `${imagem4}`,
        "date": "06/09/2023"
    },
    {
        "id": 5,
        "title": "PARTICIPE DAS AUDIÊNCIAS PÚBLICAS DO PLANO DE MOBILIDADE URBANA DE ITAJUBÁ!",
        "content": "\n Nestes eventos serão apresentados os objetivos e metas do Plano para melhorar a circulação viária e os serviços de transporte do município. Neste espaço você poderá dar suas sugestões de melhorias e acompanhar a finalização das propostas do Plano. Participe!\n\nConfira as datas e locais das audiências: \n➡️ 27/11 (18h30): E.M. Olímpio José Joaquim\n➡️ 27/11 (19h): Comunidade Santa Rita de Cássia\n➡️ 27/11 (19h): E.M. Francisco Pereira Coutinho\n➡️ 28/11 (17h): Associação do Bairro São Pedro\n➡️ 28/11 (18h): E.M. Francisco Florêncio da Silva\n➡️ 28/11 (19h): E.E. Major João Pereira\n➡️ 29/11 (18h30): APAE Rural\n➡️ 29/11 (19h): E.E. Florival Xavier\n➡️ 29/11 (19h): E.M. Alcides Faria\n➡️ 30/11 (19h): EM Teodomiro Santiago\n➡️ 01/12 (19h): Associação do Bairro Santa Rosa\n\n Confira o conteúdo já elaborado aqui no site e acesse também nossas redes sociais e fique por dentro de todas as novidades!",
        "img": `${imagem5}`,
        "date": "15/11/2023"
    },
    {
        "id": 6,
        "title": "2ª RODADA DE AUDIÊNCIAS PÚBLICAS DO PLANO DE MOBILIDADE URBANA DE ITAJUBÁ",
        "content": "\n A 2º rodada de audiências públicas do Plano de Mobilidade Urbana de Itajubá aconteceu dos dias 27 de novembro à 1 de dezembro, em 11 locais espalhados pelo município. \n\nA equipe da DAC Engenharia fez a apresentação do prognóstico do Plano, com o cenário da circulação viária para o horizonte de 10 anos e as propostas de melhorias para os modos e serviços de transporte do município. O conteúdo técnico do texto preliminar da minuta de Lei do Plano também foi apresentado. Depois de cada apresentação, foi aberto espaço para dúvidas e sugestões por parte da população. \n\nConfia todo o conteúdo elaborado na aba “Documentos”!!\n\nA apresentação da segunda rodada de audiências está disponível para download. \n\nO link para assistir a transmissão no site da prefeitura é: <a href='https://www.youtube.com/watch?v=Wy2jouOgNCc' target='_blank' style='color: blue'>https://www.youtube.com/watch?v=Wy2jouOgNCc</a>",
        "img": `${imagem6}`,
        "date": "11/12/2023"
    }
]