import React, { useEffect, useState } from "react";
import { useFormik } from 'formik'
import * as Yup from 'yup';
import './ResearcherForm.css'
import { useNavigate, useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import { cpf } from "cpf-cnpj-validator";
import { checkRespondentData } from "../../../services/Api";
import Spinner from "../../spinner/Spinner";
import { useAnswer } from "../../../contexts/AnswerContext";
import axios from "axios";
import PhoneInput from "../../Inputs/PhoneMask/PhoneMask";

export default function ResearcherForm() {
    const [concluded, _] = useState([1, 2]);

    const navigate = useNavigate();
    const [startTime, setStartTime] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { getQuestions } = useAnswer();
    const { id } = useParams();
    const [dummy, setDummy] = useState(false);


    useEffect(() => {
        if(concluded.includes(Number(id))){
            alert("Essa pesquisa não está mais recebendo respostas");
            navigate('/');
        }

        setStartTime(new Date());
    }, [concluded, id])


    const searchCEP = async (e) => {
        e.preventDefault();
        let cleancep = formik.values.cep.replace('-', '');
        if (cleancep.length === 8) {
            const result = await axios({
                method: 'get',
                url: `https://viacep.com.br/ws/${cleancep}/json/`
            });

            if (result.status === 200) {
                Object.assign(formik.values, result.data)
            }
        }
        setDummy(!dummy);
    }

    const formik = useFormik({
        initialValues: {
            questionary: 0,
            startTime: "",
            name: "",
            cpf: "",
            phone: "",
            email: "",
            location: "",
            cep: "",
            uf: "",
            localidade: "",
            bairro: "",
            logradouro: "",
            complemento: "",
            numero: "",
            address: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().min(2, 'O nome deve ter no mínimo 3 caractéres').max(200, 'Limite de caracteres atingido').required('Informe o nome do Respondente'),
            cpf: Yup.string().required("Informe um CPF").test((value) => cpf.isValid(value)),
            phone: Yup.string().min(14).max(15).required("Informe o número de telefone"),
            email: Yup.string().email('Digite um e-mail válido').optional('Informe um e-mail válido'),
            location: Yup.string().optional("Informe a localização/linha atual"),
            cep: Yup.string().min(9, "CEP inválido").optional("Informe o seu CEP"),
            complemento: Yup.string().optional("Informe o complemento"),
            numero: Yup.string().required("Informe o número de sua residência"),
            uf: Yup.string().required("Informe o seu estado"),
            localidade: Yup.string().required("Informe a sua cidade"),
            bairro: Yup.string().required("Informe o seu bairro"),
            logradouro: Yup.string().required("Informe o seu logradouro"),
        }),
        onSubmit: async (e) => {
            const questionary = Number(id);

            e.questionary = questionary
            e.startTime = startTime;
            e.address = `${e.cep}, ${e.logradouro}, ${e.numero}, ${e.complemento}, ${e.bairro}, ${e.localidade}, ${e.uf}`;

            setIsLoading(true);
            const { cpf, email } = e;

            let result;

            checkRespondentData({ questionary, cpf, email }).then(async (data) => {
                if (data.statusCode === 200) {
                    await getQuestions(questionary).then((response) => {
                        result = response;
                    })

                } else {
                    alert(data.message);
                    navigate(0)
                }
            }).then(() => {
                setIsLoading(false);

                if (result.newState !== undefined) {
                    navigate('/pesquisa/formulario', { state: { respondentData: e, data: result } });
                } else {
                    alert("ocorreu um erro, tente novamente");
                    navigate(0);
                }
            });

        }
    })

    return (
        <div className="ResearcherForm">
            <div className="poll">
                {isLoading ? (
                    <Spinner customText={"Carregando "} />
                ) : (
                    <form className="form-respondent" onSubmit={formik.handleSubmit}>

                        <div className="title">
                            <h2>Dados do Respondente</h2>
                        </div>

                        <input
                            type="text"
                            id="name"
                            className="form-control"
                            value={formik.values.name}
                            placeholder="Nome"
                            onChange={formik.handleChange}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="error">{formik.errors.name}</div>
                        ) : null}
                        <div className="form-group">
                            <div>
                                <InputMask
                                    type="text"
                                    id="cpf"
                                    className="form-control"
                                    value={formik.values.cpf}
                                    placeholder="CPF"
                                    mask="999.999.999-99"
                                    maskChar={null}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.cpf && formik.errors.cpf ? (
                                    <div className="error">{formik.errors.cpf === "cpf is invalid" ? "CPF inválido" : formik.errors.cpf}</div>
                                ) : null}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="email"
                                    className="form-control"
                                    value={formik.values.email}
                                    placeholder="E-mail"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="error">{formik.errors.email}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group">
                            <div>
                                <PhoneInput
                                    type="text"
                                    id="phone"
                                    className="form-control"
                                    value={formik.values.phone}
                                    placeholder="Telefone"
                                    onChange={formik.handleChange}
                                />

                                {formik.touched.phone && formik.errors.phone ? (
                                    <div className="error">{formik.errors.phone}</div>
                                ) : null}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="location"
                                    className="form-control"
                                    value={formik.values.location}
                                    placeholder="Local/Linha"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.location && formik.errors.location ? (
                                    <div className="error">{formik.errors.location}</div>
                                ) : null}
                            </div>
                        </div>
                        <hr />
                        <div className="form-group">
                            <div>
                                <InputMask
                                    type="text"
                                    id="cep"
                                    className="form-control"
                                    mask="99999-999"
                                    maskChar={null}
                                    value={formik.values.cep}
                                    placeholder="CEP"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.cep && formik.errors.cep ? (
                                    <div className="error">{formik.errors.cep}</div>
                                ) : null}
                            </div>
                            <div>
                                <button className="btn-search" type="button" onClick={(event) => { searchCEP(event) }}> Pesquisar </button>
                            </div>
                        </div>

                        <div className="form-group">
                            <div>
                                <input
                                    type="text"
                                    id="uf"
                                    className="form-control"
                                    value={formik.values.uf}
                                    placeholder="Estado"
                                    onChange={formik.handleChange}

                                />
                                {formik.touched.uf && formik.errors.uf ? (
                                    <div className="error">{formik.errors.uf}</div>
                                ) : null}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="localidade"
                                    className="form-control"
                                    value={formik.values.localidade}
                                    placeholder="Cidade"
                                    onChange={formik.handleChange}

                                />
                                {formik.touched.localidade && formik.errors.localidade ? (
                                    <div className="error">{formik.errors.localidade}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group">
                            <div>
                                <input
                                    type="text"
                                    id="bairro"
                                    className="form-control"
                                    value={formik.values.bairro}
                                    placeholder="Bairro"
                                    onChange={formik.handleChange}

                                />
                                {formik.touched.bairro && formik.errors.bairro ? (
                                    <div className="error">{formik.errors.bairro}</div>
                                ) : null}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="logradouro"
                                    className="form-control"
                                    value={formik.values.logradouro}
                                    placeholder="Logradouro"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.logradouro && formik.errors.logradouro ? (
                                    <div className="error">{formik.errors.logradouro}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group">
                            <div>
                                <input
                                    type="text"
                                    id="complemento"
                                    className="form-control"
                                    value={formik.values.complemento}
                                    placeholder="Complemento (Ex. Casa, Apto)"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.complemento && formik.errors.complemento ? (
                                    <div className="error">{formik.errors.complemento}</div>
                                ) : null}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="numero"
                                    className="form-control"
                                    value={formik.values.numero}
                                    placeholder="Número"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.numero && formik.errors.numero ? (
                                    <div className="error">{formik.errors.numero}</div>
                                ) : null}
                            </div>
                        </div>

                        <button type="submit" className="btn-submit" > Continuar </button>
                    </form>)}
            </div>
        </div >
    );
};