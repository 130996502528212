import React, { useEffect, useState } from "react";
import { useFormik } from 'formik'
import * as Yup from 'yup';
import './RespondentForm.css'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import InputMask from "react-input-mask";
import { checkRespondent } from "../../../services/Api";
import { useAnswer } from "../../../contexts/AnswerContext";
import Spinner from "../../spinner/Spinner";

export default function RespondentForm() {
    const [concluded, _] = useState([1, 2]);

    const navigate = useNavigate();
    const [dummy, setDummy] = useState(false);
    const [startTime, setStartTime] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { getQuestions } = useAnswer();
    const { id } = useParams();

    useEffect(() => {
        if(concluded.includes(Number(id))){
            alert("Essa pesquisa não está mais recebendo respostas");
            navigate('/');
        }

        setStartTime(new Date());
    }, [concluded, id])


    const searchCEP = async (e) => {
        e.preventDefault();
        let cleancep = formik.values.cep.replace('-', '');
        if (cleancep.length === 8) {
            const result = await axios({
                method: 'get',
                url: `https://viacep.com.br/ws/${cleancep}/json/`
            });

            if (result.status === 200) {
                Object.assign(formik.values, result.data)
            }
        }
        setDummy(!dummy);
    }
    const formik = useFormik({
        initialValues: {
            questionary: 0,
            startTime: "",
            cep: "",
            uf: "",
            localidade: "",
            bairro: "",
            logradouro: "",
            complemento: "",
            numero: "",
            address: "",
        },
        validationSchema: Yup.object({
            cep: Yup.string().min(9, "CEP inválido").optional("Informe o seu CEP"),
            complemento: Yup.string().optional("Informe o complemento"),
            numero: Yup.string().required("Informe o número de sua residência"),
            uf: Yup.string().required("Informe o seu estado"),
            localidade: Yup.string().required("Informe a sua cidade"),
            bairro: Yup.string().required("Informe o seu bairro"),
            logradouro: Yup.string().required("Informe o seu logradouro"),
        }),
        onSubmit: async (e) => {
            const questionary = Number(id);
            e.questionary = questionary
            e.startTime = startTime;
            e.address = `${e.cep}, ${e.logradouro}, ${e.numero}, ${e.complemento}, ${e.bairro}, ${e.localidade}, ${e.uf}`;
            setIsLoading(true);

            let result;

            checkRespondent({ questionary }).then(async (data) => {
                if (data.statusCode === 200) {
                    await getQuestions(questionary).then((response) => {
                        result = response;
                    })

                } else {
                    alert(data.message);
                    navigate(0)
                }
            }).then(() => {
                setIsLoading(false);
                if (result.newState !== undefined) {
                    navigate('/pesquisa/formulario', { state: { respondentData: e, data: result } });
                } else {
                    alert("ocorreu um erro, tente novamente");
                    navigate(0);
                }
            });


        }
    })

    return (
        <div className="RespondentForm">
            <div className="poll-respondent">
                {isLoading ? (
                    <Spinner customText={"Carregando "} />
                ) : (
                    <form className="form-respondent" onSubmit={formik.handleSubmit}>

                        <div className="title">
                            <h2>Dados do Respondente</h2>
                            <br />
                            <h4>Antes de continuar, informe o seu CEP e pressione pesquisar!</h4>
                        </div>
                        <hr />

                        <div className="form-group">
                            <div>
                                <InputMask
                                    type="text"
                                    id="cep"
                                    className="form-control"
                                    mask="99999-999"
                                    maskChar={null}
                                    value={formik.values.cep}
                                    placeholder="CEP"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.cep && formik.errors.cep ? (
                                    <div className="error">{formik.errors.cep}</div>
                                ) : null}
                            </div>
                            <div>
                                <button className="btn-search" type="button" onClick={(event) => { searchCEP(event) }}> Pesquisar </button>
                            </div>
                        </div>

                        <div className="form-group">
                            <div>
                                <input
                                    type="text"
                                    id="uf"
                                    className="form-control"
                                    value={formik.values.uf}
                                    placeholder="Estado"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.uf && formik.errors.uf ? (
                                    <div className="error">{formik.errors.uf}</div>
                                ) : null}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="localidade"
                                    className="form-control"
                                    value={formik.values.localidade}
                                    placeholder="Cidade"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.localidade && formik.errors.localidade ? (
                                    <div className="error">{formik.errors.localidade}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group">
                            <div>
                                <input
                                    type="text"
                                    id="bairro"
                                    className="form-control"
                                    value={formik.values.bairro}
                                    placeholder="Bairro"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.bairro && formik.errors.bairro ? (
                                    <div className="error">{formik.errors.bairro}</div>
                                ) : null}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    id="logradouro"
                                    className="form-control"
                                    value={formik.values.logradouro}
                                    placeholder="Logradouro"
                                    onChange={formik.handleChange} />
                                {formik.touched.logradouro && formik.errors.logradouro ? (
                                    <div className="error">{formik.errors.logradouro}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group">
                            <div>
                                <input
                                    type="text"
                                    id="complemento"
                                    className="form-control"
                                    value={formik.values.complemento}
                                    placeholder="Complemento (Ex. Casa, Apto)"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.complemento && formik.errors.complemento ? (
                                    <div className="error">{formik.errors.complemento}</div>
                                ) : null}
                            </div>
                            <div>
                                <input
                                    type="number"
                                    id="numero"
                                    className="form-control"
                                    value={formik.values.numero}
                                    placeholder="Número"
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.numero && formik.errors.numero ? (
                                    <div className="error">{formik.errors.numero}</div>
                                ) : null}
                            </div>
                        </div>
                        <button type="submit" className="btn-submit" > Continuar </button>
                    </form>
                )}
            </div>
        </div >
    );
};