import { useFormik } from 'formik';
import { contact } from '../../services/Api';
import * as Yup from 'yup';

import './Contato.css'

function Contato() {

    const initialValues = {
        contactName: "",
        contactEmail: "",
        contactSubject: "",
        contactDescription: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            contactName: Yup.string().required("Informe o seu nome"),
            contactEmail: Yup.string().email().required("Informe o seu email"),
            contactSubject: Yup.string().required("Informe o assunto"),
            contactDescription: Yup.string().required("A mensagem não pode ser deixada em branco"),
        }),
        onSubmit: async (e) => {
            contact({
                plan: 1,
                name: e.contactName,
                email: e.contactEmail,
                subject: e.contactSubject,
                description: e.contactDescription,
            }).then((result) => {
                if (result.status === 200 || result.status === 250) {
                    alert('Email enviado com sucesso')
                }
            }).finally(() => {
                formik.values = initialValues;

            });
        }
    })


    return (
        <div className='Contato'>
            <div className="center">
                <div className="Contato-card">
                    <div className="Contato-title">
                        <h2>Entre em Contato</h2>
                        <p> Preencha os campos abaixo para recebermos sua mensagem!</p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="Contato-inputs">
                            <div className='inputs-left'>
                                <div className='input-nome'>
                                    <label htmlFor='contactName'>Nome</label>
                                    <input
                                        id="contactName"
                                        name="contactName"
                                        type="text"
                                        placeholder='Digite seu Nome'
                                        value={formik.values.contactName}
                                        onChange={formik.handleChange}
                                        style={(formik.errors.contactName) ? { border: "solid 1px #ff0000" } : { border: "solid 1px #135463a2" }}
                                    />
                                </div>
                                <div className='input-nome'>
                                    <label htmlFor="contactEmail">Email</label>
                                    <input
                                        id="contactEmail"
                                        type="email"
                                        placeholder='exemplo@exemplo.com'
                                        value={formik.values.contactEmail}
                                        onChange={formik.handleChange}
                                        style={(formik.errors.contactEmail) ? { border: "solid 1px #ff0000" } : { border: "solid 1px #135463a2" }}

                                    />
                                </div>
                                <div className='input-assunto'>
                                    <label htmlFor='contactSubject'>Assunto</label>
                                    <input
                                        id="contactSubject"
                                        name='contactSubject'
                                        type="text"
                                        placeholder='Digite o Assunto'
                                        value={formik.values.contactSubject}
                                        onChange={formik.handleChange}
                                        style={(formik.errors.contactSubject) ? { border: "solid 1px #ff0000" } : { border: "solid 1px #135463a2" }}
                                    />
                                </div>
                            </div>
                            <div className='input-mensagem'>
                                <label htmlFor='contactDescription'>Mensagem</label>
                                <textarea
                                    id="contactDescription"
                                    name='contactDescription'
                                    placeholder='Digite a Mensagem'
                                    value={formik.values.contactDescription}
                                    onChange={formik.handleChange}
                                    style={(formik.errors.contactDescription) ? { border: "solid 1px #ff0000" } : { border: "solid 1px #135463a2" }}
                                />
                            </div>
                        </div>
                        <div className="Contato-button">
                            <button type="submit">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contato;