import { useAnswer } from '../../../contexts/AnswerContext';

function Text(data) {

    const { question, _class } = data;

    const { answers, handleTextInputChange } = useAnswer();

    if (answers[question.id] === undefined) {
        answers[question.id] = '';
    }

    return (
        <div className='Text'>
            {(() => {
                let type
                if (question.metric === 4) {
                    type = 'number';
                } else {
                    type = 'text'
                }
                return (
                    <div className="poll-answers-text">
                        <div className="poll-question-text">
                            <input
                                className={_class || ''}
                                type={type}
                                min='0'
                                placeholder="Resposta: "
                                id={question.id}
                                name={'answer' + question.id}
                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                onChange={(event) => { handleTextInputChange(event) }}
                                value={answers[question.id]}
                            />
                        </div>
                    </div>
                )
            })()}
        </div>
    )
}

export default Text;