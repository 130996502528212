import { useState } from 'react';
import { useAnswer } from '../../../contexts/AnswerContext';

function Checkbox(data) {
    const [dummy, setDummy] = useState(false);

    const { handleCheckboxInputChange, handleTextInputChange, checkedState, answers } = useAnswer();

    const { options, question } = data;

    return options.map((option) => {
        return (
            <div className="poll-question" key={option.id}>
                {(() => {
                    if (option.action === "OTHER_TEXT") {
                        if (answers[question.position] === undefined) {
                            answers[question.position] = '';
                        }
                        return (
                            <div className="other-text" >
                                <label className="other-text-check">
                                    {option.value}.
                                    <input
                                        type="checkbox"
                                        name={"answer" + question.id}
                                        id={question.position + '-' + option.position}
                                        defaultChecked={checkedState[question.position][option.position]}
                                        onChange={(event) => {
                                            handleCheckboxInputChange((event), question, options);
                                            setDummy(!dummy);
                                        }}
                                    />
                                    {option.description}:

                                </label>

                                <input
                                    type='text'
                                    id={question.position}
                                    name={'answer' + question.id}
                                    className='other-text-input'
                                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    disabled={!checkedState[question.position][option.position]}
                                    onChange={(event) => { handleTextInputChange(event) }}
                                    value={answers[question.id]}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div className="answer-text">
                                <label className="answer-check">
                                    {option.value}.
                                    <input
                                        type="checkbox"
                                        name={"answer" + question.id}
                                        id={question.position + '-' + option.position}
                                        defaultChecked={checkedState[question.position][option.position]}
                                        onChange={(event) => {
                                            handleCheckboxInputChange((event), question, options);
                                            setDummy(!dummy);
                                        }}
                                    />
                                    {option.description}

                                </label>
                            </div>
                        )

                    }
                })()}
            </div >

        );
    });

}

export default Checkbox;