import { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai/'
import './Password.css'

export default function PasswordField({ values }) {
    const [hidePassword, setHidePassword] = useState(true);

    return (
        <div className='password' style={values.style}>

            <input
                type={hidePassword ? 'password' : 'text'}
                placeholder={values.placeholder}
                className='passwordInput'
                name={values.name}
                onChange={values.onChange}
                onBlur={values.onBlur}
                value={values.value}
            />
            <label
                className='passwordToggle'
                htmlFor='passwordCheck'
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setHidePassword(!hidePassword);
                }}>

                <input type='checkbox' className='passwordCheck' />
                {hidePassword ? <AiOutlineEye size={25} /> : <AiOutlineEyeInvisible size={25} />}
            </label>
        </div>
    )
}