import { useState } from 'react';
import { useAnswer } from '../../../contexts/AnswerContext';

function Select(data) {
    const [dummy, setDummy] = useState(false);

    const { handleSelectInputChange, selected } = useAnswer();

    const { options, question, _class } = data;

    return (
        <div className="poll-question-select">
            <select
                id={question.id}
                name={'answer' + question.id}
                onChange={(event) => {
                    handleSelectInputChange(event)
                    setDummy(!dummy)
                }}
                defaultValue={selected[question.id] || " "}
                className={_class || ''}
            >
                <option value=" " disabled hidden>Selecione</option>
                {(() => {
                    return options.map((option) => {
                        return (<option key={option.id} value={option.position}>{option.description}</option>)
                    })
                })()}
            </select>
        </div >
    )
}

export default Select;