import React from 'react';
import '../Global.css';
import Navbar from '../components/Navbar/Navbar';
import Banner from '../components/Home/Banner/Banner';
import Plane from '../components/Home/Plane/Plane';
import Social from '../components/Home/Social/Social';
import Steps from '../components/Home/Steps/Steps';
import Footer from '../components/Footer/Footer';
import Contato from '../components/Contato/Contato';

function Home() {

  return (
    <div className="Home">
      <Navbar />
      <Banner />
      <Plane />
      <Social />
      <Steps />
      <Contato />
      <Footer />
    </div>
  );
}

export default Home;