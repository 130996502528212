import './Plane.css'

function Plane() {
    return (
        <div className='Plane'>
            <div className='plane-about'>
                <div className='plane--text'>
                    <h2>Sobre o plano</h2>
                    <p>O Plano de Mobilidade Urbana – PMU, é uma ferramenta para planejar
                        o desenvolvimento da mobilidade urbana sustentável no município.
                        Mobilidade urbana é a capacidade das pessoas e cargas se deslocarem no
                        município. Logo, o Plano estuda as condições das vias e os modos de
                        transporte disponíveis, como caminhar, andar de bicicleta, transporte coletivo,
                        automóveis, taxi, cargas, transporte por aplicativo, entre outros.
                        A partir desse estudo, são identificados os pontos positivos e negativos
                        e propostas melhorias para a mobilidade no município. Para que a mobilidade
                        seja sustentável, ações devem focar em priorizar os modos não motorizados e
                        o transporte coletivo. O PMU é exigido pela Lei Federal nº12.587 de 2012
                        – Política Nacional de Mobilidade Urbana para municípios com mais de
                        20 mil habitantes.</p>
                </div>
            </div>
        </div>
    )
}

export default Plane;