import React from 'react';
import '../Global.css';
import ResearcherForm from '../components/Polls/ResearcherForm/ResearcherForm';


function Researcher() {
    return (
        <div className="Polls">
            <div>
                {<ResearcherForm />}
            </div>
        </div>
    )
}

export default Researcher;
