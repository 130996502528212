import React, { useEffect, useRef, useState } from 'react';
import './SubForm.css'
import Select from "../../Inputs/Select/Select";
import Text from "../../Inputs/Text/Text";
import TimeInput from "../../Inputs/TimePicker/TimePicker";
export default function SubForm(data) {
  const control = useRef(0);
  const [lastRepeat, setLastRepeat] = useState();
  const rows = useRef([]);
  const { repeat, questions, dummy, setDummy } = data;

  useEffect(() => {
    if (control.current === 0) {
      setDummy(!dummy);
      control.current = 1;
    }
  }, [setDummy, dummy])


  const getTitle = (group) => {
    switch (group) {
      case "OD_children":
        return "Filho ";
      default:
        return "Questionario ";
    }
  }

  if (repeat !== lastRepeat) {
    setLastRepeat(repeat);
    rows.current = []
    for (let index = 1; index <= repeat; index++) {
      rows.current.push(<Questionary index={index} key={index} />);
    }
  }


  function Questionary({ index }) {
    return (
      <div className='card'>
        <h3> {getTitle(questions[0].group)} {index}</h3>
        {questions.map((q) => {
          let question = JSON.parse(JSON.stringify(q));
          question.id = 'OD_children' + index + question.position;
          switch (question.metric) {
            case 3:
              return (
                <div>
                  <label htmlFor={question.id}>{question.title}</label>
                  <Text
                    _class={'subformQuestion'}
                    question={{
                      question
                    }}
                  />
                </div>)
            case 4:
              return (
                <div>
                  <label htmlFor={question.id}>{question.title}</label>
                  <Text
                    _class={'subformQuestion'}
                    question={question}
                  />
                </div>
              )
            case 5:
              return (
                <div>
                  <label htmlFor={question.id}>{question.title}</label>
                  <Select
                    _class={'subformQuestion'}
                    question={question}
                    options={question.options}
                  />
                </div>
              )
            case 6:
              return (
                <div>
                  <label htmlFor={question.id}>{question.title}</label>
                  <TimeInput
                    _class={'subformQuestion'}
                    question={question}
                  />
                </div>
              )
            default:
              break;
          }
        })}
      </div>
    );
  }

  return (

    <div>
      <div className='quizdisplay'>
        {
          rows.current
        }
      </div>
    </div>

  );

}

