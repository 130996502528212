import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL

})
api.defaults.headers.get['Access-Control-Allow-Origin'] = '*';



export const getQuestionary = async (id) => {
    try {
        const response = await api.get(`/questions/${id}`);
        const { questions, options } = response.data;
        return {
            statusCode: 200,
            questions,
            options,
        };
    } catch (err) {
        return err;
    }
}

export const getPolls = async () => {

    try {
        const response = await api.get('/questionaries', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return {
            statusCode: response.status,
            polls: response.data,

        };
    } catch (err) {
        if (err.response && err.response.data) {
            return {
                statusCode: err.response.data.statusCode,
                message: err.response.data.message,
            };
        }
        else {
            console.log(err);
            return err;
        }
    }

}
//used by researcher
export const checkRespondentData = async (data) => {
    try {
        const response = await api.post(`/respondents/checkData`,
            data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return {
            statusCode: response.status,
            message: response.statusText,
        };
    } catch (err) {
        if (err.response && err.response.data) {
            return {
                statusCode: err.response.data.statusCode,
                message: err.response.data.message,
            };
        }
        else {
            console.log(err);
            return err;
        }
    }
}

//used by respondent
export const checkRespondent = async (data) => {
    try {
        const response = await api.post(`/respondents/check`,
            data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return {
            statusCode: response.status,
            message: response.statusText,
        };
    } catch (err) {
        if (err.response && err.response.data) {
            return {
                statusCode: err.response.data.statusCode,
                message: err.response.data.message,
            };
        }
        else {
            console.log(err);
            return err;
        }
    }
}

export const postAnswersRespondent = async (data) => {
    try {
        const response = await api.post(`/answers/respondent`,
            data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return {
            statusCode: response.data.statusCode,
            message: response.data.message,
        };
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const postAnswersResearcher = async (data) => {
    try {
        const response = await api.post(`/answers/researcher`,
            data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return {
            statusCode: response.data.statusCode,
            message: response.data.message,
        };
    } catch (err) {
        return err;
    }
}

export const contact = async (data) => {
    try {
        const response = await api.post(`/contact`,
            data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (err) {
        alert('Ocorreu um erro, tente novamente mais tarde');
        console.log(err);
    }
}

export const sendForgotPasswordEmail = async (data) => {
    try {
        const response = await api.post(`/users/forgot_password`,
            data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (err) {
        if (err.response.status === 400) {
            alert("O Email informado não foi encontrado");
        } else {
            console.log(err);
        }
    }
}

export const validateToken = async (data) => {
    try {
        const response = await api.post(`/users/validate`,
            data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (err) {
        return err;
    }
}

export const UpdatePassword = async (data) => {
    try {
        const response = await api.post(`/users/update_password`,
            data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (err) {
        return err;
    }
}


export const DownloadXls = async (id) => {
    try {
        const response = await api.get(`answers/report/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        return response
    } catch (err) {
        return err;
    }
}