import React, { useState } from "react";
import { useAnswer } from "../../../contexts/AnswerContext";

function Radio(data) {
    const [dummy, setDummy] = useState(false);

    const { handleRadioInputChange, handleTextInputChange, checkedState, answers } = useAnswer();

    const { options, question, likertOptions, likertScale } = data;

    if (question.metric === 1) {
        return (
            <div className="poll-answers-lickert">
                {(() => {
                    return likertOptions.map(option => {
                        // ===== Lickert Scale =====
                        return (
                            <div className="lickert-options" key={question.id + '' + Math.random(100)}>
                                <label>
                                    <i className={checkedState[question.position][option.position] ? "fas " + likertScale[option.id].icon : "far " + likertScale[option.id].icon}></i>
                                    <input
                                        type="radio"
                                        name={"answer" + question.id}
                                        id={question.position + '-' + option.position}
                                        defaultChecked={checkedState[question.position][option.position]}
                                        onChange={(event) => handleRadioInputChange((event), likertOptions)}
                                    />
                                    <i>{option.description}</i>
                                </label>
                            </div>
                        );
                    })
                })()}
            </div>
        )
    } else if (question.metric === 2) {
        //              ===== Multiple Answer =====
        return options.map((option) => {
            if (option.action === "OTHER_TEXT") {
                if (answers[question.position] === undefined) {
                    answers[question.position] = '';
                }
                return (
                    <div className="other-text" key={option.id}>
                        <label className="other-text-radio">
                            {option.value}.
                            <input
                                type="radio"
                                name={"answer" + question.id}
                                id={question.position + '-' + option.position}
                                defaultChecked={checkedState[question.position][option.position]}
                                onChange={(event) => {
                                    handleRadioInputChange((event), options);
                                    setDummy(!dummy);
                                }}
                            />
                            {option.description}:

                        </label>
                        <input
                            type='text'
                            id={question.position}
                            name={'answer' + question.id}
                            className='other-text-input'
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            disabled={!checkedState[question.position][option.position]}
                            onChange={(event) => { handleTextInputChange(event) }}
                            value={answers[question.id]}
                        />
                    </div>
                )
            } else {
                return (
                    <div className="poll-question" key={option.id}>
                        <div className="answer-text">
                            <label className="answer-check">
                                {option.value}.
                                <input
                                    type="radio"
                                    name={"answer" + question.id}
                                    id={question.position + '-' + option.position}
                                    defaultChecked={checkedState[question.position][option.position]}
                                    onChange={(event) => handleRadioInputChange((event), options)}
                                />
                                {option.description}
                            </label>
                        </div>
                    </div>
                );
            };
        });

    }
}

export default Radio;